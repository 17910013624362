@import "nlib/config";
@import "animations";

.actions {
  display: flex;
  width: 100%;
  button {
    flex: 1;
    font-weight: normal;
    svg {
      font-size: 1.125rem;
    }
    &.warning {
      svg {
        color: $uiWarningColor;
      }
    }
  }
  button,
  .buttonWrap {
    position: relative;
    + button,
    .buttonWrap {
      margin-left: $contentDefaultIndent;
    }
  }
  .processing {
    svg {
      animation: rotate 1s linear infinite;
    }
  }
}
