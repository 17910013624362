@import "nlib/config";

.askClientPopup {
  .card {
    position: relative;
    flex-direction: column;
    width: 40rem;
    margin-top: $contentDefaultIndent * 2;
    box-shadow: 0 0 0 2px $uiWarningColor;
    font-weight: 500;
    .comment {
      margin-bottom: $contentDefaultIndent * 1.5;
    }
    .main {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.5rem;
      height: 1.5rem;
      margin-right: $contentDefaultIndent * 1.5;
      border-radius: 50%;
      background-color: $uiWarningColor;
      box-shadow: 0 0 0 4px rgba($uiWarningColor, 0.2);
      color: $uiWhiteColor;
      font-size: 1rem;
    }
    .text {
      margin-right: $contentDefaultIndent;
    }
    .button {
      height: 2.5rem;
      border: none;
      font-size: 0.875rem;
      font-weight: 500;
      svg {
        font-size: 1rem;
      }
    }
    .actions {
      display: flex;
      margin-left: auto;
      button {
        overflow: hidden;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.withDropdown {
        > .button:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .dropdownToggle {
        border: 1px solid $uiWarningColor;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $uiWhiteColor;
        &:hover {
          border-left-color: $uiWhiteColor;
          svg {
            color: $uiWhiteColor;
          }
        }
        svg {
          color: $uiDarkColor;
        }
      }
    }
  }
  .closeButton {
    $buttonWidth: 1.5rem;
    display: flex;
    position: absolute;
    top: -($buttonWidth / 2);
    right: -($buttonWidth / 2);
    align-items: center;
    justify-content: center;
    width: $buttonWidth;
    height: $buttonWidth;
    transform: translate3d(0, 0, 0);
    border-radius: 50%;
    background-color: $uiWhiteColor;
    box-shadow: 0 0 12px rgba(57, 57, 68, 0.2);
    color: $uiDarkColor;
    cursor: pointer;
    &:hover {
      background-color: $uiNegativeColor;
      color: $uiWhiteColor;
    }
    svg {
      font-size: 1rem;
    }
  }
  .caret {
    padding-left: 0;
  }
}

.dropdownContent {
  width: fit-content;
  min-width: 16rem !important;
  max-width: 26rem;
  padding: $contentDefaultIndent 0;
  color: $uiDarkColor;
  .text {
    flex: auto;
    overflow: hidden;
    color: $uiDarkColor;
    text-overflow: ellipsis;
  }
  .badge {
    min-width: fit-content;
    margin-left: $contentDefaultIndent;
    text-transform: lowercase;
  }
}

[data-mobile] {
  .askClientPopup {
    bottom: 86px;
    width: 100%;
    max-width: 32rem;
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    .card {
      width: 100%;
      font-weight: normal;
      .comment {
        display: none;
      }
      .main {
        flex-wrap: wrap;
        .icon {
          margin: $contentDefaultIndent 0;
        }
        .text {
          flex: auto;
          margin: $contentDefaultIndent;
          text-align: center;
        }
        .actions {
          margin: $contentDefaultIndent auto 0;
          overflow: hidden;
        }
      }
    }
    .closeButton {
      top: 0;
      right: 0;
      width: 2rem;
      height: 2rem;
    }
  }
  .list {
    padding: 0 $contentDefaultIndent * 2;
  }
}
