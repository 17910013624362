@import "nlib/config";

.comments {
  height: 100%;
  max-height: 100%;
  .dialog {
    height: 100%;
  }
  .content {
    padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2 !important;
    overflow: hidden;
  }
}
