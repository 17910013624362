@import "nlib/config";

.editModal {
  .label {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .input {
    margin-bottom: $contentDefaultIndent * 3;
  }
  .buttons {
    display: flex;
    button {
      flex: 1;
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
