@import "nlib/config";

.categoryAndReasonInput {
  .switcher {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    + div {
      .input {
        input {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
  .label {
    font-size: 0.75rem;
    font-weight: 500;
  }
}
