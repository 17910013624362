@import "nlib/config";

.transactionInfo {
  svg {
    color: $uiMediumDarkColor;
    font-size: 1rem;
  }
  .mainInfo {
    display: flex;
    margin-bottom: 2em;
    .timestamp {
      margin-right: $contentDefaultIndent;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.25rem;
    }
    .amount {
      margin-left: auto;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.25rem;
    }
  }
  .additionalInfo {
    font-size: 0.8125rem;
    .row {
      display: flex;
      align-items: center;
      align-items: center;
      margin-bottom: $contentDefaultIndent;
      svg {
        font-size: 1rem;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .description {
    &.warning {
      svg {
        color: $uiWarningColor;
      }
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .reason {
    svg {
      color: $uiHighlightColor;
    }
  }
  &.documentPreviewMode {
    .infoWrapper {
      display: flex;
      margin-bottom: $contentDefaultIndent * 1.5;
    }
    .mainInfo {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 0;
      .amount {
        margin-right: $contentDefaultIndent * 3;
        margin-left: 0;
      }
      .timestamp {
        margin-right: $contentDefaultIndent * 2;
        font-weight: normal;
      }
    }
    .additionalInfo {
      margin-bottom: 0;
      font-size: 0.875rem;
    }
  }
}
