@import "nlib/config";

.confidenceAppend {
  position: relative;
  white-space: nowrap;
  svg {
    font-size: 1rem;
  }
  .confidence {
    display: flex;
    position: absolute;
    top: 50%;
    right: $contentDefaultIndent;
    width: 32px;
    transform: translate(0, -50%);
    border-radius: 4px;
  }
  &.showIcon {
    input {
      padding-right: 3.75rem !important;
    }
    :global(.caret) {
      right: 2rem;
    }
  }
  .badge {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    overflow: visible;
    border-radius: 4px;
    background-color: transparent;
    background-size: contain;
    color: inherit;
    text-align: center;
    cursor: default;
  }
}
