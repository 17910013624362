@import "nlib/config";

.extraDataInputs {
  .input {
    margin-bottom: $contentDefaultIndent * 2;
    .label {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
  &.documentPreviewMode {
    .input {
      flex: 1;
      + .input {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
}
