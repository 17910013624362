@import "nlib/config";
@import "animations";

.attachment {
  min-width: 3rem;
  height: 3rem;
  .hidden {
    display: none;
  }
  .dropdownContent {
    width: fit-content !important;
    .menuItem {
      padding-right: $contentDefaultIndent * 2;
      padding-left: $contentDefaultIndent * 2;
      svg {
        font-size: 1.125rem;
      }
      + .menuItem {
        border-top: 1px solid $uiBorderColor;
      }
    }
  }
  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 2px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    svg {
      animation: rotate 1s infinite linear;
      font-size: 1.25rem;
    }
    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}
