@import "nlib/config";

.transactionAmount {
  overflow: hidden;
  text-overflow: ellipsis;
  &.negative {
    color: $uiNegativeColor;
  }
  &.positive {
    color: $uiPositiveColor;
  }
}
