@import "nlib/config";

.editModal {
  .input {
    margin-bottom: $contentDefaultIndent * 3;
  }
  .buttons {
    display: flex;
    button {
      flex: 1;
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
