@import "nlib/config";

.transactionsPage {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding-bottom: $mobileNavBarHeight;
  .preloader {
    z-index: 4;
  }
  .accountTitle {
    position: sticky;
    z-index: 4;
    top: 0;
    margin-bottom: $contentDefaultIndent;
    transition: box-shadow 0.2s;
    background-color: $uiBackgroundColor;
    box-shadow: 0 4px 10px rgba(26, 28, 32, 0);
    font-size: 1rem;
    font-weight: 500;
    > div {
      display: flex;
      align-items: center;
      height: 3.25rem;
    }
    &:not(:first-child) {
      margin-top: $contentDefaultIndent;
    }
    &.shadow {
      box-shadow: 0 6px 10px rgba(26, 28, 32, 0.05);
    }
    &.withMargin {
      top: 4.5rem;
    }
    svg {
      min-width: 1.25rem;
      margin-right: $contentDefaultIndent;
      color: $uiNeutralColor;
      font-size: 1.25rem;
    }
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .transactionsPopup {
    bottom: 4.25rem;
  }
  .pagination {
    justify-content: center;
    padding: $contentDefaultIndent * 2 $contentDefaultIndent;
  }
  .statusFilter {
    margin-top: $contentDefaultIndent * 2;
  }
  .popup {
    bottom: 4rem;
  }
}
