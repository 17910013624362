@import "nlib/config";

.attachedDocumentActions {
  display: flex;
  justify-content: center;
  width: 100%;
  .button {
    flex: 1;
    max-width: 10rem;
    text-decoration: none;
    &.edit {
      color: $uiPrimaryColor;
    }
    + .button {
      margin-left: $contentDefaultIndent;
    }
  }
  &.compact {
    justify-content: center;
    .button {
      width: 22px;
      max-width: 22px;
      height: 22px;
      padding: 0;
      border-radius: 50%;
      svg {
        margin: 0;
      }
    }
  }
}
