@import "nlib/config";

.listItemDetails {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .transactionInfo {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .category {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .class {
    margin-top: $contentDefaultIndent;
  }
  .separator {
    position: relative;
    color: $uiNeutralColor;
    line-height: 1;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      border-bottom: 1px solid $uiBorderColor;
    }
    span {
      position: relative;
      z-index: 1;
      padding: 0 $contentDefaultIndent * 4;
      background: #fff;
    }
  }
  .document {
    margin-top: $contentDefaultIndent * 2;
  }
  .contact {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .input {
    margin-bottom: $contentDefaultIndent * 1.5;
  }
  &.documentPreviewMode {
    .inputsWrapper {
      display: flex;
      flex-wrap: wrap;
      .input {
        flex: 1;
        + .input {
          margin-left: $contentDefaultIndent * 2;
        }
      }
      .contact {}
      .category {
        margin-top: 0;
      }
      .extraDataInputs {
        display: flex;
        flex: 1;
        min-width: 100%;
      }
    }
  }
  .pdfViewerContainer {
    position: relative;
    flex: 1;
    margin-top: $contentDefaultIndent * 2;
    margin-bottom: $contentDefaultIndent * 5;
    .pdfViewer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .attachedDocumentActions {
      position: relative;
      top: 100%;
      width: fit-content;
      margin-top: $contentDefaultIndent;
      margin-right: auto;
      margin-left: auto;
      button {
        min-width: 10rem;
      }
    }
  }
  .label {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .activity {
    margin-top: 3px;
  }
  .row {
    display: flex;
    flex: auto;
    flex-direction: column;
    .col {
      margin-bottom: $contentDefaultIndent * 1.5;
    }
  }
}

@media (min-width: $viewportSizeMedium) {
  .listItemDetails {
    .row {
      flex-direction: row;
      .col {
        width: 50%;
        + .col {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
}
