@import "nlib/config";

.lastComment {
  display: flex;
  .prepend {
    margin-top: $contentDefaultIndent / 4;
    margin-right: $contentDefaultIndent;
  }
  .text {
    position: relative;
    flex: 1;
    margin-right: $contentDefaultIndent * 2;
    padding-left: 0.75rem;
    word-break: break-word;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 1px;
      width: 3px;
      border-radius: 3px;
      background-color: $uiMediumDarkColor;
    }
  }
  &.noComment {
    .text {
      color: $uiMediumDarkColor;
      &::after {
        background-color: $uiMediumDarkColor;
      }
    }
  }
  &.unread {
    .text {
      &::after {
        background-color: $uiNegativeColor;
      }
    }
  }
  .link {
    color: $uiPrimaryColor;
  }
}
