@import "nlib/config";

.bulkActions {
  position: relative;
  position: sticky;
  z-index: 4;
  top: 0;
  margin-top: -$contentDefaultIndent * 1.5;
  padding: $contentDefaultIndent * 1.5 0;
  background-color: $uiWhiteColor;
  .container {
    display: flex;
    align-items: center;
    padding: 0 $contentDefaultIndent * 2;
  }
  .text {
    flex: 1;
    margin-right: $contentDefaultIndent;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    button {
      width: 3rem;
      + button {
        margin-left: $contentDefaultIndent * 2;
      }
      svg {
        font-size: 1rem;
      }
    }
  }
}
