@import "nlib/config";

.document {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: $contentDefaultIndent * 2 0;
  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 1rem;
    svg {
      min-width: 1.125rem;
      margin-right: $contentDefaultIndent;
      color: $uiPrimaryColor;
      font-size: 1.125rem;
      &[disabled] {
        color: $uiDarkColor;
      }
    }
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .preloader {
    font-size: 2rem;
  }
  .buttons {
    width: fit-content;
    margin: $contentDefaultIndent;
  }
  .fileInfo {
    display: flex;
    flex: auto;
    align-items: center;
    max-width: 100%;
    margin: $contentDefaultIndent;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    .icon svg {
      min-width: 2.5rem;
      margin-right: $contentDefaultIndent;
      font-size: 2.5rem;
    }
    .name {
      overflow: hidden;
      font-size: 1rem;
      text-decoration: underline;
      text-overflow: ellipsis;
    }
    &:hover {
      .icon {
        opacity: 0.75;
      }
      .name {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $viewportSizeExtraSmall) {
  .attached {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
