@import "nlib/config";

.listItem {
  width: 100%;
  margin: 0 auto $contentDefaultIndent;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 0 1px 4px rgba(70, 70, 70, 0.1);
  .header {
    display: flex;
    align-items: center;
    height: 2.75rem;
    padding-right: $contentDefaultIndent * 2;
    .checkBox {
      padding: $contentDefaultIndent * 2;
    }
    .timestamp {
      margin-right: $contentDefaultIndent;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      white-space: nowrap;
    }
    .amountWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-left: auto;
      .amount {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.25rem;
        line-height: 1;
      }
      .amountTotal {
        display: flex;
        color: $uiMediumDarkColor;
        font-size: 0.8125rem;
        line-height: 1;
      }
    }
  }

  .body {
    padding: 0 $contentDefaultIndent * 2;
    .content {
      display: flex;
      align-items: center;
      padding-bottom: $contentDefaultIndent * 1.5;
      .wrapper {
        flex: auto;
        padding-right: $contentDefaultIndent;
        overflow: hidden;
      }
      button {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        svg {
          font-size: 1.125rem;
        }
      }
      svg {
        + span {
          margin-left: $contentDefaultIndent;
        }
      }
      .row {
        display: flex;
        line-height: 1.2;
        + .row {
          margin-top: $contentDefaultIndent;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.invalid {
          svg {
            color: $uiNegativeColor;
          }
        }
        &.valid {
          svg {
            color: $uiPositiveColor;
          }
        }
        &.highlight {
          svg {
            color: $uiPrimaryColor;
          }
        }
        &.warning {
          svg {
            color: $uiWarningColor;
          }
        }
      }
    }
  }
  .attachment {
    margin-right: $contentDefaultIndent;
  }
  .footer {
    padding: 0 $contentDefaultIndent * 2;
    .content {
      padding: $contentDefaultIndent * 1.5 0;
      border-top: 1px solid $uiBorderColor;
    }
  }
  &.highlightRow,
  &.positiveRow,
  &.negativeRow,
  &.warningRow {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0.625rem;
      bottom: 0.625rem;
      left: 0;
      width: 0.25rem;
      border-radius: 0.125rem;
      background-color: $uiPrimaryColor;
    }
  }
  &.highlightRow {
    &::before {
      background-color: $uiHighlightColor;
    }
  }
  &.positiveRow {
    &::before {
      background-color: $uiPositiveColor;
    }
  }
  &.negativeRow {
    &::before {
      background-color: $uiNegativeColor;
    }
  }
  &.warningRow {
    &::before {
      background-color: $uiWarningColor;
    }
  }
  .block {
    + .block {
      margin-top: $contentDefaultIndent * 1.5;
    }
  }
  .inputs {
    margin-bottom: $contentDefaultIndent;
  }
}

@media (min-width: $viewportSizeMedium) {
  .listItem {
    .inputs {
      display: flex;
      margin-bottom: 0;
      .block {
        width: 50%;
        margin-top: 0;
        margin-bottom: $contentDefaultIndent * 1.5;
        + .block {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
}
